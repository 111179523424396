<script setup>
import Lottie from "~/components/lottie/Lottie";
import animation_llajlbf4 from '~/assets/lottie/animation_llajlbf4.json'
import {Spin} from 'ant-design-vue';
import {LoadingOutlined} from '@ant-design/icons-vue';

const props = defineProps({
  spinning: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    default: "default", // default | lottiePhoneAnimate
  },
  tips: {
    type: String,
    default: '',
  },
  size: {
    type: String,
    default: 'large',
  }
});

const show = (loadingType) => {
  let propsType = '';
  switch (props.type) {
    case 'lottiePhoneAnimate':
      propsType = props.type
      break
    default:
      propsType = 'default';
  }

  return loadingType === propsType;
}

</script>
<template>
  <Spin :spinning="spinning" :size="size">
    <template #indicator>
      <div>
        <LoadingOutlined
            v-if="show('default')"
            size="16px"
        />

        <Lottie
            v-if="show('lottiePhoneAnimate')"
            style="margin-left: -100px"
            :data="animation_llajlbf4"
            size="250px"
        />
      </div>
    </template>
    <slot/>
  </Spin>

</template>
<style lang="scss" scoped>

</style>
